<template>
  <div>

    <b-overlay
      id="overlay-background"
      :show="pageIsLoading"
      variant="light"
      opacity="0.85"
      blur="5px"
      rounded="sm"
    >
      <b-alert
        show
        dismissible
        fade
        class="mb-0"
        variant="secondary"
      >
        <div class="alert-body ">
          <span>Heads up! Any changes that you have made to Settings will only be applied to new invoices.</span>
        </div>
      </b-alert>
      <template #overlay>
        <b-card class="text-center">

          <h2>
            <b-spinner
              class="mr-1"
              label="Loading"
            />
            {{ overlayMsg }}
          </h2>

        </b-card>
      </template>
      <b-card>
        <b-row>
          <b-col
            align-h="center"
            md="3"
            class="border-right"
            style="display:flex; flex-direction: column;"
          >
            <status-alert
              :is-success="isSuccess"
              :error-data="errorData"
              :success-msg="$t('Invoice design updated')"
            />
            <div class="d-flex justify-content-between align-items-center">
              <b-link :to="{ name: 'settings' }"> <feather-icon
                icon="ArrowLeftIcon"
                size="30"
                class="mr-50"
              />
              </b-link>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="lg"
                variant="primary"
                @click="saveData"
              >
                Save design
              </b-button>
            </div>
            <h1 class="mb-2 mt-2 ml-2 font-weight-bolder">
              Invoice Design
            </h1>
            <app-collapse
              accordion
              style="overlay:auto;"
            >
              <h4>Design</h4>
              <app-collapse-item title="Template">
                <b-container class="bv-example-row">
                  <b-row>
                    <!-- latest photo loop -->
                    <b-col
                      v-for="data in InvoiceDesignOptions.themes"
                      :key="data.img"
                      md="6"
                      cols="6"
                      class="profile-latest-img d-flex justify-content-center list-item"
                      :class="{ 'selected': data.name == designData.selectedTheme }"
                    >
                      <b-link @click="designData.selectedTheme = data.name">
                        <b-row>
                          <b-img
                            fluid
                            rounded
                            :src="data.img"
                            :alt="data.label"
                          />
                        </b-row>
                        <h5 class="text-center mt-1 font-weight-bolder">
                          {{ data.label }}
                        </h5>
                      </b-link>
                    </b-col>
                    <!-- latest photo loop -->
                  </b-row>
                </b-container>
              </app-collapse-item>
              <app-collapse-item title="Logo">
                <b-tabs>

                  <b-tab
                    active
                    title="Image"
                  >
                    <file-uploader
                      ref="logoUploader"
                      :accepts="'.jpg, .png, .jpeg'"
                      @fileUploaded="logoUploaded"
                    />
                    <div class="d-flex justify-content-start flex-wrap">

                      <b-link @click="openLogoUplader">
                        <div
                          class="text-center bg-primary colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center  ml-50 my-1 shadow"
                        >

                          <feather-icon
                            icon="PlusSquareIcon"
                            size="30"
                          />
                        </div>

                      </b-link>
                      <b-img
                        v-if="logoImage"
                        fluid
                        v-bind="imageProps"
                        :src="logoImage"
                        rounded
                        alt="Logo"
                        class="d-inline-block  border"
                      />
                      <b-link
                        v-if="logoImage"
                        @click="removeLogo"
                      >
                        <div
                          class="text-center bg-secondary colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center  ml-50 my-1 shadow"
                        >

                          <span>REMOVE LOGO</span>
                        </div>

                      </b-link>
                    </div>
                  </b-tab>
                  <b-tab
                    title="Settings"
                    diabled
                  >
                    <b-alert
                      show
                      variant="secondary"
                    >
                      <div class="alert-body">

                        <span class="ml-25">Logo size</span>
                      </div>
                    </b-alert>
                    <b-form-group>
                      <b-form-radio-group
                        id="btn-radios-1"
                        v-model="designData.logo.size"
                        style="width:100%;"
                        button-variant="flat-primary"
                        :options="imageSizeOptions"
                        buttons
                        stacked
                        :aria-invalid="false"
                        name="radios-btn-default"
                      />
                    </b-form-group>
                    <b-alert
                      show
                      variant="secondary"
                    >
                      <div class="alert-body">

                        <span class="ml-25">Adjust logo alignment </span>
                      </div>
                    </b-alert>
                    <vue-slider
                      v-model="designData.logo.align"
                      :dot-size="35"
                      :lazy="true"
                      tooltip="none"
                      :max="100"
                    />
                  </b-tab>

                </b-tabs>

              </app-collapse-item>

              <app-collapse-item title="Color Schema">
                <div class="d-flex justify-content-start flex-wrap">
                  <b-link
                    v-for="data in InvoiceDesignOptions.colors"
                    :key="data"
                    @click="designData.themeColor = data"
                  >
                    <div
                      :style="'background-color:' + data"
                      class="text-center  colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center  ml-50 my-1 shadow"
                    >
                      <feather-icon
                        v-if="data == designData.themeColor"
                        icon="CheckCircleIcon"
                        size="30"
                      />
                    </div>
                  </b-link>

                </div>
              </app-collapse-item>
              <hr>
              <h4>Options</h4>
              <app-collapse-item title="Header">
                <div class="d-flex flex-column mt-1">
                  <b-form-checkbox
                    v-if="true == false"
                    v-model="designData.options.header.showShippingDetails"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                    :value="true"
                    :unchecked-value="false"
                    class="mb-2 cursor-pointer"
                  >
                    <h5 class="font-weight-bolder">
                      Shipping details
                    </h5>
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="designData.options.header.showDueDate"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                    :value="true"
                    :unchecked-value="false"
                    class="mb-2 cursor-pointer"
                  >
                    <h5 class="font-weight-bolder">
                      Due date
                    </h5>
                  </b-form-checkbox>
                  <div class="d-flex flex-column mt-1">
                    <div
                      v-for="(data, index) in designData.options.header.customFields"
                      :key="index"
                      class="mb-1 d-flex justify-content-between align-items-center"
                    >
                      <span><strong>{{ data.name }} </strong> {{ data.value }}</span>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        small
                        variant="flat-danger"
                        class="ml-2"
                        @click="designData.options.header.customFields.splice(index, 1)"
                      >
                        Delete
                      </b-button>
                    </div>
                  </div>
                  <div v-if="showHeaderFieldEditor">
                    <validation-observer ref="headerCustomFieldForm">
                      <validation-provider
                        #default="{ errors }"
                        name="Field name"
                        rules="required"
                      >
                        <b-form-group
                          label="Field name"
                          label-for="headerFieldNameInput"
                        >
                          <b-form-input
                            id="headerFieldNameInput"
                            v-model="headerFieldData.name"
                            placeholder="Type the field name..."
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                      <b-form-group
                        label="Field value"
                        label-for="headerFieldValueInput"
                      >
                        <b-form-input
                          id="headerFieldValueInput"
                          v-model="headerFieldData.value"
                          placeholder="Type the field value..."
                        />

                      </b-form-group>

                    </validation-observer>
                    <b-alert
                      show
                      variant="primary"
                    >
                      <div class="alert-body">

                        <span class="ml-25">Default value is optional and is displayed on all new invoices.</span>
                      </div>
                    </b-alert>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="flat-success"
                      @click="addHeaderField"
                    >
                      Save
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="flat-danger"
                      @click="cancelHeaderField"
                    >
                      Cancel
                    </b-button>
                  </div>
                  <b-button
                    v-if="!showHeaderFieldEditor"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-primary"
                    @click="showHeaderFieldEditor = true"
                  >
                    Add field
                  </b-button>
                </div>
              </app-collapse-item>
              <app-collapse-item title="Labels">
                <b-tabs>
                  <b-tab title="General">
                    <b-form-group
                      v-for="labelItem, index in designData.options.labels.general"
                      v-show="labelItem.value != 'code'"
                      :key="index"
                      :label="labelItem.label"
                      :label-for="'label-' + labelItem.value"
                    >
                      <b-form-input
                        :id="'label-' + labelItem.value"
                        v-model="labelItem.input"
                        placeholder="Enter label..."
                      />

                    </b-form-group>
                  </b-tab>
                  <b-tab title="Headings">
                    <b-form-group
                      v-for="labelItem, index in designData.options.labels.document_headings"
                      :key="index"
                      :label="labelItem.label"
                      :label-for="'label-' + labelItem.value"
                    >
                      <b-form-input
                        :id="'label-' + labelItem.value"
                        v-model="labelItem.input"
                        placeholder="Enter label..."
                      />

                    </b-form-group>
                  </b-tab>
                  <b-tab title="Summary">
                    <b-form-group
                      v-for="labelItem, index in designData.options.labels.document_summary"
                      :key="index"
                      :label="labelItem.label"
                      :label-for="'label-' + labelItem.value"
                    >
                      <b-form-input
                        :id="'label-' + labelItem.value"
                        v-model="labelItem.input"
                        placeholder="Enter label..."
                      />

                    </b-form-group>
                  </b-tab>
                </b-tabs>
              </app-collapse-item>
              <app-collapse-item title="Table">
                <div class="d-flex flex-column mt-1">
                  <b-alert
                    show
                    variant="secondary"
                  >
                    <div class="alert-body">

                      <span class="ml-25">Columns</span>
                    </div>
                  </b-alert>
                  <b-form-checkbox
                    v-if="true == false"
                    v-model="designData.options.table.showItemCode"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                    :value="true"
                    :unchecked-value="false"
                    class="mb-2 cursor-pointer"
                  >
                    <h5 class="font-weight-bolder">
                      Item code
                    </h5>
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="designData.options.table.showQuantityAndRate"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                    :value="true"
                    :unchecked-value="false"
                    class="mb-2 cursor-pointer"
                  >
                    <h5 class="font-weight-bolder">
                      Quantity and Unit Price
                    </h5>
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="designData.options.table.showTax"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                    :value="true"
                    :unchecked-value="false"
                    class="mb-2 cursor-pointer"
                  >
                    <h5 class="font-weight-bolder">
                      Tax
                    </h5>
                  </b-form-checkbox>
                </div>
              </app-collapse-item>
              <app-collapse-item title="Footer">
                <div class="d-flex flex-column mt-1">
                  <b-form-checkbox
                    v-model="designData.options.footer.showSignatureLine"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                    :value="true"
                    :unchecked-value="false"
                    class="mb-2 cursor-pointer"
                  >
                    <h5 class="font-weight-bolder">
                      Show signature line
                    </h5>
                  </b-form-checkbox>
                  <b-form-group
                    label="Generic comment"
                    label-for="footerGenericCommentInput"
                  >
                    <b-form-input
                      id="footerGenericCommentInput"
                      v-model="designData.options.footer.comments.genericComment"
                      placeholder="Add generic comment..."
                    />

                  </b-form-group>
                  <b-form-group
                    label="Terms and conditions"
                    label-for="footerTermsAndConditionsInput"
                  >
                    <b-form-input
                      id="footerTermsAndConditionsInput"
                      v-model="designData.options.footer.comments.termsAndConditions"
                      placeholder="Add terms and conditions..."
                    />

                  </b-form-group>
                </div>
              </app-collapse-item>

              <app-collapse-item title="Numbering">
                <b-modal
                  id="resetNumberingModal"
                  ref="resetNumberingModal"
                  title="Reset invoice numbering"
                  ok-only
                  ok-title="Save"
                  @ok="saveResetNumbering"
                >
                  <validation-observer ref="invoiceNumberResetForm">
                    <b-alert
                      show
                      variant="secondary"
                    >
                      <div class="alert-body">

                        <span class="ml-25">Editing where numbering begins will determine the number of your next
                          invoice. Future invoice numbers will increase sequentially.

                        </span>
                      </div>
                    </b-alert>
                    <validation-provider
                      #default="{ errors }"
                      name="Invoice number start"
                      rules="required|integer|between:1,9999999999"
                    >
                      <b-form-group
                        label="Numbering begins at..."
                        label-cols-md="6"
                      >
                        <b-form-input
                          id="invoiceNumberPrefix"
                          v-model="invoiceNumberData"
                          v-numeric-only
                          type="number"
                          placeholder="Enter a number..."
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                      <b-alert
                        show
                        variant="secondary"
                      >
                        <div class="alert-body">

                          <span class="ml-25">Your next invoice number will be: #{{ invoiceNumberData }}</span>
                        </div>
                      </b-alert>
                    </validation-provider>
                  </validation-observer>

                </b-modal>

                <div class="d-flex flex-column mt-1">

                  <b-form-group
                    label="Invoice number prefix"
                    label-for="invoiceNumberPrefix"
                  >
                    <b-form-input
                      id="invoiceNumberPrefix"
                      v-model="designData.options.numbers.invoiceNumberPrefix"
                      placeholder="Enter a prefix..."
                    />

                  </b-form-group>
                  <b-alert
                    show
                    variant="secondary"
                  >
                    <div class="alert-body">

                      <span class="ml-25">Add a prefix to invoice numbers (e.g. INV)</span>
                    </div>
                  </b-alert>
                  <b-alert
                    show
                    variant="secondary"
                  >
                    <div class="alert-body">

                      <span class="ml-25">Your next invoice number will be: #{{ invoiceNumbering.last_invoice_number + 1
                      }}</span>
                    </div>
                  </b-alert>
                  <b-button
                    v-if="!showHeaderFieldEditor"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-primary"
                    @click="showInvoiceNumberResetter"
                  >
                    Reset numbering
                  </b-button>

                </div>
              </app-collapse-item>
            </app-collapse>
          </b-col>
          <b-col md="9">

            <invoice
              v-if="invoiceData.design"
              :invoice-data="invoiceData"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BLink, BContainer, BButton, BOverlay, BSpinner, BAlert, BFormCheckbox, BFormGroup, BFormInput, BModal, BTabs, BTab, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Invoice from '@core/components/invoice-themes/Template.vue'
// eslint-disable-next-line no-unused-vars
import InvoiceDesignOptions from '@core/components/invoice-themes/design-options'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import fileUploader from '@core/components/file-uploader/FileUploader.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/named
import { required, min } from '@validations'
// import merge from 'deepmerge'
import VueSlider from 'vue-slider-component'
import accountStoreModule from '../../accountStoreModule'

export default {
  components: {
    BRow,
    BCol,
    AppCollapse,
    VueSlider,
    AppCollapseItem,
    BCard,
    Invoice,
    BFormCheckbox,
    BImg,
    BLink,
    BContainer,
    BButton,
    BOverlay,
    BSpinner,
    BAlert,
    fileUploader,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BModal,
    BTabs,
    BTab,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      // validation rules
      required,
      min,
      // validation rules

      isSuccess: false,
      errorData: null,
      pageIsLoading: true,
      loadingMsg: 'Loading...',
      savingMsg: 'Saving...',
      overlayMsg: '',
      InvoiceDesignOptions,
      imageSizeOptions: [
        { text: 'Small', value: 'sm' },
        { text: 'Medium', value: 'md' },
        { text: 'Large', value: 'lg' },
      ],

      imageProps: {
        blank: false,
        width: 100,
        block: false,
        height: 100,
        class: 'ml-50 my-1',
      },
      logoImage: null,
      showHeaderFieldEditor: false,
      headerFieldData: {
        name: '',
        value: '',
      },
      invoiceNumberData: 0,
      invoiceNumberDataOriginal: 0,
      blankDesignData: {
        selectedTheme: 'standard',
        themeColor: '#1CA085',

        logo: {
          size: 'md',
          align: 0, // margin left mm
          file: null,
        },
        options: {

          labels: {
            general: [
              { value: 'amount', label: 'Amount', input: 'Amount' },
              { value: 'code', label: 'Code', input: 'Code' },
              { value: 'description', label: 'Description', input: 'Description' },
              { value: 'quantity', label: 'Quantity', input: 'Quantity' },
              { value: 'unit_price', label: 'Unit price', input: 'Unit Price' },
             // { value: 'tax', label: 'Tax', input: 'Tax' },

            ],
            document_headings: [
              { value: 'bill_to', label: 'Bill to', input: 'Bill To' },
              { value: 'for', label: 'For', input: 'For' },
              { value: 'invoice', label: 'Invoice', input: 'invoice' },
              { value: 'invoice_no', label: 'Invoice no', input: 'Invoice No' },
              { value: 'due_date', label: 'Due date', input: 'Due Date' },
              { value: 'invoice_date', label: 'Invoice date', input: 'Invoice Date' },

            ],
            document_summary: [
              { value: 'balance_due', label: 'Balance due', input: 'Balance Due' },
              { value: 'company_signature', label: 'Company\'s signature', input: 'Company\'s Signature' },
              { value: 'client_signature', label: 'Client\'s signature', input: 'Client\'s Signature' },
              { value: 'notes', label: 'Notes', input: 'Notes' },
              { value: 'terms_and_condictions', label: 'Terms and condictions', input: 'Terms and Condictions' },
              { value: 'total', label: 'Total date', input: 'Total' },
              { value: 'includes', label: 'Includes', input: 'Includes' },

            ],
          },
          header: {
            showShippingDetails: false,
            showDueDate: true,
            customFields: [],
          },
          table: {
            showItemCode: false,
            showQuantityAndRate: false,
            showTax: true,
          },
          footer: {
            comments: {
              genericComment: '',
              termsAndConditions: '',
            },
            showSignatureLine: true,
          },
          numbers: {
            invoiceNumberPrefix: '',
          },

        },
      },
      designData: null,
      invoiceNumbering: {
        last_invoice_number: 0,
      },
      invoiceData: {
        design: null,
        content: {
          currency_code: 'ALL',
          billing: {
            address: 'Example Client Address.',
            email: 'info@exampleclient.com',
            mobile: '604 444 55 66',
            name: 'Example Client Company',
            phone: '',
          },

          invoice_date: null,
          due_date: null,
          invoice_number: '1',
          taxes: [
            { tax_rate: 18, id: 'd3', tax_name: 'Tax' }],
          withholding_tax: {
            name: 'Withholding tax',
            rate: 10,
          },
          items: [{
            code: 'ex-01',
            desription: 'details',
            name: 'Example product',
            quantity: 2,
            unit_price: 10,
            unit_type: 'parts',
            withholding_tax_applies: false,
            applied_taxes: [
              { tax_rate: 5, tax_id: 'd3' },
            ],
          },
          {
            code: 'ex-02',
            desription: 'details',
            name: 'Other product',
            quantity: 1,
            unit_price: 15,
            unit_type: 'parts',
            withholding_tax_applies: false,
            applied_taxes: [
              { tax_rate: 5, tax_id: 'd3' },
            ],
          }],
          shipping: null,

          company_info: {
            name: 'Fast Invoice inc',
            additional_information: '',
            address: 'Fast Invoice canada office',
            email: 'info@fastinvoice.me',
            website: 'fastinvoice.me',
            phone: '604 444 55 66',
            tkn: 'test-tkn-number',
            gst: '000_000_000',
            wbc: '000 000 00',

          },
        },

      },
    }
  },
  computed: {

  },
  watch: {
    designData: {
      handler() {
        this.reRenderPreview()
      },
      deep: true,

    },
  },
  created() {
    this.designData = JSON.parse(JSON.stringify(this.blankDesignData))
    this.overlayLoading()
    this.fetchInvoiceDesign()
    this.invoiceData.content.due_date = this.$moment().format('YYYY-MM-DD')
    this.invoiceData.content.invoice_date = this.$moment().format('YYYY-MM-DD')
    this.$userHandler.setUsageStep('invoice_design_checked')
  },
  methods: {
    fillDesignFromUserData() {
      const user = this.$user()
      this.invoiceData.content.currency_code = user.currency.currency_code
    },
    showInvoiceNumberResetter() {
      this.$refs.resetNumberingModal.show()
      this.invoiceNumberData = this.invoiceNumbering.last_invoice_number + 1
    },
    saveResetNumbering(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.invoiceNumberResetForm.validate().then(success => {
        if (success) {
          this.invoiceNumbering.last_invoice_number = this.invoiceNumberData - 1
          this.$nextTick(() => {
            this.$refs.resetNumberingModal.hide()
          })
        }
      })
    },
    addHeaderField() {
      this.$refs.headerCustomFieldForm.validate().then(success => {
        if (success) {
          this.designData.options.header.customFields.push(JSON.parse(JSON.stringify(this.headerFieldData)))
          this.headerFieldData.name = ''
          this.headerFieldData.value = ''
          this.showHeaderFieldEditor = false
        }
      })
    },
    cancelHeaderField() {
      this.headerFieldData.name = ''
      this.headerFieldData.value = ''
      this.showHeaderFieldEditor = false
    },
    removeLogo() {
      this.logoImage = null
      this.designData.logo.file = null
    },
    openLogoUplader() {
      this.$refs.logoUploader.openUploader()
    },
    logoUploaded(fileData) {
      this.designData.logo.file = fileData

      this.logoImage = fileData.url
    },
    overlaySaving() {
      this.pageIsLoading = true
      this.overlayMsg = this.savingMsg
    },
    overlayLoading() {
      this.overlayMsg = this.loadingMsg
      this.pageIsLoading = true
    },
    overlayEnd() {
      this.pageIsLoading = false
    },
    fetchInvoiceDesign() {
      store
        .dispatch('app-account/fetchAccountData')
        .then(response => {
          this.overlayEnd()
          if (response.data.data.invoice_design != null) {
            const userData = response.data.data
            const obj1 = JSON.parse(JSON.stringify(this.blankDesignData))
            const obj2 = JSON.parse(JSON.stringify(response.data.data.invoice_design))
            this.invoiceNumbering = response.data.data.invoice_numbering
            this.invoiceNumberData = response.data.data.invoice_numbering.last_invoice_number
            this.invoiceNumberDataOriginal = response.data.data.invoice_numbering.last_invoice_number
            if (response.data.data.invoice_design.logo.file) {
              this.logoImage = response.data.data.invoice_design.logo.file.url
            }
            if (obj2.options.labels) {
             // obj1.options.labels = obj2.options.labels
            }

            const mergedObject = {
            ...obj1,
            ...obj2,
            } // merge(obj1, obj2)
            // console.log(mergedObject)
            this.invoiceData.content.company_info.phone = userData.company_info.phone
            this.invoiceData.content.company_info.name = userData.company_info.business_name
            this.invoiceData.content.company_info.email = userData.company_info.other_email
            this.invoiceData.content.address = userData.company_info.street_address
            this.invoiceData.content.company_info.tkn = userData.tax_and_currency_info.tkn
            this.invoiceData.content.additional_information = userData.company_info.additional_information
            this.invoiceData.content.withholding_tax.name = userData.tax_and_currency_info.withholding_tax_label
            this.invoiceData.content.withholding_tax.rate = userData.tax_and_currency_info.withholding_tax_rate
            this.invoiceData.content.currency_code = userData.tax_and_currency_info.currency.currency_code
            this.invoiceData.content.currency_symbol = userData.tax_and_currency_info.currency.symbol
            this.designData = mergedObject
            this.reRenderPreview()
          }
        })
        .catch(() => {
          this.overlayEnd()
        })
    },
    saveData() {
      const postParams = { invoice_design: this.designData }

      if (this.invoiceNumberDataOriginal !== this.invoiceNumbering.last_invoice_number) {
        postParams.last_invoice_number = this.invoiceNumbering.last_invoice_number
      }
      this.overlaySaving()

      store
        .dispatch('app-account/updateInvoiceDesign', postParams)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.overlayEnd()
          //  this.userData = response.data.data
          this.$userHandler.setUsageStep('customize_invoice_design')
          this.isSuccess = true
          this.errorData = null
        })
        .catch(error => {
          this.overlayEnd()
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    reRenderPreview() {
      this.invoiceData.design = this.designData
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-account'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, accountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    return {}
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style>
.list-item {
  border: 2px solid transparent;
}

.selected {
  border-color: var(--primary);
}
</style>
